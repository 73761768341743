<template>

    <auth-content>
      <el-header :title="$t(`modules.presence.title`)" slot="header" permissions="presence" />

      <div class="card">

        <div class="card-header py-2 border-bottom pr-2 d-flex align-items-center justify-content-between">
          <el-card-title :title="calendarTitle" />
          <div class>
            <el-table-action
              @click.prevent.native="loadPreviousWeek()"
              icon="icon-arrow-left8"
            />
            <el-table-action
              @click.prevent.native="loadNextWeek()"
              icon="icon-arrow-right8"
            />
          </div>
        </div>

        <div class="table-wrapper">
          <table
            class="table table-striped table-borderless table-hover"
          >
            <thead>
              <tr>
                <th class="font-weight-normal text-grey">
                  {{ $t('common.week') }}&nbsp;{{ currentWeek }}
                </th>
                <th v-for="weekDay in weekDays" class="text-nowrap text-center text-capitalize">
                  {{ weekDay.date | moment('ddd D') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in users">
                <td class="">
                  <div class="d-flex align-items-center">
                    <el-avatar :src="user.avatar.thumb || user.avatar"/>
                    <span class="pl-2">{{ user.full_name || user.email }}</span>
                  </div>
                </td>
                <td class="text-center" v-for="(weekDay, weekDate) in weekDays">
                  <div v-if="loading">
                    <loader-bar />
                  </div>
                  <div class="" v-else-if='daysData'>

                    <div v-if="
                      (
                        user.id == currentUser.id &&
                        userHasPermission('presence', 'manage_own')
                      ) ||
                      userHasPermission('presence', 'manage_module')
                    ">
                      <div class="presence-dropdown-toggle rounded cursor-pointer d-flex align-items-center dropdown-toggle w-100 justify-content-center" data-toggle="dropdown">
                        <span class="px-2">
                          <span class="m-0 h1" v-if="daysData[weekDate] && daysData[weekDate][user.id]">
                            {{ presenceValues[daysData[weekDate] && daysData[weekDate][user.id].data.place].emoji }}
                          </span>
                          <span v-else class="text-grey py-1">—</span>
                        </span>
                        <i class="icon-arrow-down22" />
                      </div>
                      <div class="dropdown-menu">
                        <div class="font-weight-bold text-capitalize px-2 py-1">{{ weekDate | moment('dddd D MMMM YYYY') }}</div>
                        <div
                          v-for="(presenceValue, presenceKey) in presenceValues"
                          class="dropdown-item py-0"
                          @click="setPresence(user.id, weekDate, presenceKey)"
                        >
                          <span class="h1 m-0 mr-2">
                            {{ presenceValue.emoji }}
                          </span>
                          <span>
                            {{ $t(`modules.presence.values.${presenceKey}`) }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="d-flex align-items-center justify-content-center" v-else>
                      <span class="px-2">
                        <span class="m-0 h1" v-if="daysData[weekDate] && daysData[weekDate][user.id]">
                          {{ presenceValues[daysData[weekDate] && daysData[weekDate][user.id].data.place].emoji }}
                        </span>
                        <span v-else class="text-grey">—</span>
                      </span>
                    </div>

                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>

      </div>

    </auth-content>

</template>

<script>

import Fuse from 'fuse.js'
import _ from 'lodash'
import moment from 'moment'

import {
  PRESENCE_GET_WEEK,
  PRESENCE_SET_DAY,
} from '../mutations-types'

export default {
  name: 'PresenceList',
  data () {
    return {
      currentWeek: null,
      currentYear: null,
      usersData: null,
      daysData: null,
      loading: false,
      presenceValues: {
        office: {
          emoji: '💼',
        },
        home: {
          emoji: '🏠',
        },
        holidays: {
          emoji: '🏖',
        },
        sick: {
          emoji: '🤕',
        },
        off_other: {
          emoji: '❌',
        },
      }
    }
  },
  mounted () {
    moment.updateLocale('en', {
      week: {
        dow : 1, // Monday is the first day of the week.
      }
    })
    this.currentYear = moment().year()
    this.currentWeek = moment().isoWeek()
    this.loadDaysData(this.currentYear, this.currentWeek)
  },
  methods: {
    loadPreviousWeek () {
      let currentDate = this.currentDate
      let newDate = moment(currentDate).subtract(1, 'week');
      this.currentYear = newDate.format('YYYY')
      this.currentWeek = newDate.format('W')
      this.loadDaysData(this.currentYear, this.currentWeek)
    },
    loadNextWeek () {
      let currentDate = this.currentDate
      let newDate = moment(currentDate).add(1, 'week');
      this.currentYear = newDate.format('YYYY')
      this.currentWeek = newDate.format('W')
      this.loadDaysData(this.currentYear, this.currentWeek)
    },
    loadDaysData (year = null, week = null) {
      this.loading = true
      this.$store.dispatch('presence/' + PRESENCE_GET_WEEK, {
        worldId: this.$route.params.world,
        year: year,
        week: week,
      }).then(data => {
        this.daysData = data.data
        this.usersData = data.users
        this.loading = false
      })
      .catch(error => {
        this.loading = false
        this.notifError(error)
      })
    },
    setPresence(userId, date, place) {
      this.$store.dispatch('presence/' + PRESENCE_SET_DAY, {
        worldId: this.$route.params.world,
        user: userId,
        date: date,
        place: place,
      }).then(data => {
        if (!this.daysData[date]) {
          this.$set(this.daysData, date, {})
        }
        if (!this.daysData[date][userId]) {
          this.$set(this.daysData[date], userId, {})
        }
        this.$set(this.daysData[date], userId, data)
      })
      .catch(error => {
        this.notifError(error)
      })
    },
  },
  computed: {
    calendarTitle () {
      if (!this.currentYear || !this.currentWeek) {
        return ''
      }
      let date = this.currentDate
      return _.capitalize(moment(date).format('MMMM')) + ' ' + this.currentYear
    },
    currentDate () {
      return moment().day(this.weekDaysNames[0]).year(this.currentYear).isoWeek(this.currentWeek).toDate()
    },
    currentUser () {
      return this.$store.getters['auth/getProfile']
    },
    users () {
      let users = this.usersData ? this.usersData : []
      users = _.sortBy(users, [o  => { return o.id != this.currentUser.id }]);
      return users
    },
    weekDaysNames () {
      let days = moment.weekdays()
      days.push(days.shift())
      return days
    },
    weekDays () {
      if (!this.currentYear || !this.currentWeek) {
        return {}
      }

      let days = {}
      let daysNames = this.weekDaysNames

      daysNames.forEach( dayName => {
        let date = moment().day(dayName).year(this.currentYear).isoWeek(this.currentWeek).toDate()
        days[moment(date).format('YYYY-MM-DD')] = {
          'date': date,
          'dayName': dayName,
        }
      })
      return days
    },
  },
}
</script>

<style lang="scss" scoped>
.card-img-top {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
}
.presence-dropdown-toggle {
  &:hover {
    background: rgba(black, .05);
  }
  &:after {
    display: none;
  }
}
</style>
